import React, { useState, useEffect } from "react";
import { CardTool } from "tamtam-components";
import Tooltip from "../Library/Tooltip";
import IconSimpleArrowDown from "../Icons/SimpleArrowDownIcon";
import { cardsFetching } from "../../utils";
import _ from "../../i18n";
import { useSelector } from "react-redux";
import IconLoader from "components/Icons/IconLoader";
import { getWebtools } from "api";
import RightBloc from "./RightBloc";
import RequestEdit from "./RequestEdit";

export const OrganizationWebtools = ({
  title,
  subTitle,
  filter,
  workspace,
  language,
  limit,
  logoUrl,
  logoWidth,
  communityId,
  setVisibilityTooltip,
  loadingFavoriteIds,
  favoriteIds,
  setWebToolEdited,
  setWebToolDeleted,
  handleSaveFavorite,
  isEnabled,
  user,
  allowedActions,
}) => {
  const { navCommunity, token } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [webtools, setWebtools] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentWebtool, setCurrentWebtool] = useState(null);

  // fetch Organization webtools
  const fetchOrganizationWebtools = async () => {
    setIsFetching(true);
    return await getWebtools({
      token,
      limit,
      filter,
      language,
      workspace,
      page: page,
      community: communityId,
    })
      .then((res) => (res.data.data ? res.data.data : []))
      .catch(() => [])
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    if (page > 1) {
      fetchOrganizationWebtools().then((data) => {
        if (data.length === 0 || data.length < limit) {
          setHasMore(false);
        }
        setWebtools([...webtools, ...data]);
      });
    }
  }, [page]);

  useEffect(() => {
    if (token && navCommunity) {
      setPage(1);
      fetchOrganizationWebtools().then((data) => {
        if (data.length === 0 || data.length < limit) {
          setHasMore(false);
        }
        setWebtools(data);
      });
    }
  }, [token, navCommunity, favoriteIds]);

  if (!isEnabled) {
    return null;
  }

  return !isFetching && webtools.length === 0 ? null : (
    <>
      <div className="grid-x recommanded_frame ">
        <div className="cell small-12 medium-6 large-3  grid_card">
          <RightBloc
            title={title}
            subTitle={subTitle}
            logoUrl={logoUrl}
            logoWidth={logoWidth}
          />
        </div>
        <div className={"cell small-12 medium-6 large-9"}>
          <div className="grid-x">
            {isFetching && webtools.length === 0
              ? cardsFetching(6)
              : webtools.map((item, i) => {
                  const organizationName =
                    item.organizationSource &&
                    item.organizationSource.abbreviation
                      ? item.organizationSource.abbreviation
                      : item.organizationSource && item.organizationSource.name
                      ? item.organizationSource.name
                      : item.organization.abbreviation
                      ? item.organization.abbreviation
                      : item.organization.name;
                  return (
                    <div
                      className="cell small-12 medium-12 large-4 grid_card"
                      key={`rec-${item.id}`}
                    >
                      <CardTool
                        toolContent={
                          <Tooltip
                            webtool={item}
                            onSavedWebtool={setVisibilityTooltip}
                          />
                        }
                        allowedActions={{
                          favorite: true,
                          update:
                            (allowedActions || item.creator.id === user?.id) &&
                            item.organizationSource &&
                            navCommunity.id !== 9,
                        }}
                        loadingActions={{
                          favorite: loadingFavoriteIds.includes(item.id),
                        }}
                        isFavorite={favoriteIds.includes(item.id)}
                        data={{
                          id: item.id,
                          icon: item.icon.label,
                          link: item[`urlName${language}`],
                          title: item[`title${language}`],
                          description: item[`description${language}`] ?? "",
                          tag: item.category[`name${language}`],
                          created: item.createdAt
                            ? `${_("createdThe")} ${item.createdAt}`
                            : "",
                          organization: organizationName
                            ? `${_("by")} ${organizationName}`
                            : "",
                          source: item.organizationSource
                            ? _("libraryFFF")
                            : null,
                        }}
                        color={item.category.colorCode}
                        onReach={() =>
                          window.open(item[`urlName${language}`], "_blank")
                        }
                        onUpdate={() => setCurrentWebtool(item)}
                        onAddFavorite={() => handleSaveFavorite(item)}
                      />
                    </div>
                  );
                })}
          </div>
          <div className="grid_card_footer">
            {isFetching && webtools.length > 0 && (
              <div className="recommanded_footer_btn justify-center">
                <IconLoader size={36} />
              </div>
            )}
            {hasMore && !isFetching && (
              <button
                className="recommanded_footer_btn"
                onClick={() => {
                  if (hasMore) {
                    setPage(page + 1);
                  }
                }}
              >
                <span>{_("seeMore")}</span>
                <IconSimpleArrowDown />
              </button>
            )}
          </div>
        </div>
      </div>
      <RequestEdit webtool={currentWebtool} setWebtool={setCurrentWebtool} />
    </>
  );
};
export default OrganizationWebtools;
