const faqFr = {
  //Home Page
  allWebToolsOf: "Tous les webtools powered by",
  scrollSelection: "parcourir la sélection",
  seeMore: "voir plus",
  sharedWithMe: "récemment partagé avec moi",
  recommandedBy: "recommandé par",
  my: "Mes",
  webtools: "webtools",
  categories: "categories",
  addWebtool: "ajouter un webtool",
  theMost: "les plus",
  popular: "populaires",
  search: "Rechercher",
  saveSuccess: "mis a jour avec succès",
  shareSuccess: "partagé avec succès",
  savingError: "erreur de sauvegarde",
  searchResults: "Resultat de recherche:",

  title: "Titre",
  description: "Description",
  tags: "Mots clés",
  category: "Catégorie",
  save: "Enregistrer",
  update: "Modifier",
  cancel: "Annuler",
  select: "Sélectionner",
  icon: "l'icone",
  managementSpace: "Espace de gestion",

  webToolSaved: "webtool enregistré avec succès",
  webToolUpdated: "webtool mis à jour avec succès",
  webToolDeleted: "webtool supprimé avec succès",
  categorySaved: "category enregistré avec succès",
  categoryUpdated: "category mis à jour avec succès",
  categoryDeleted: "category supprimé avec succès",

  // Library Page
  webtoolsToShare: "webtools à partager",
  library: "Bibliothèque",
  myLibrary: "ma bibliothèque",
  subHeaderLibrary: "partager des webtools avec vos collaborateurs ou clients",
  allScopes: "Tous les scope",
  createdThe: "crée le",
  by: "Par",
  confirmDelete: "Confirmation de suppression",
  confirmDeleteMessage: "Êtes-vous sûr de bien vouloir supprimer cet élément ?",
  no: "non",
  yes: "oui",
  noDataFound: "Aucune donnée trouvée",
  sharedWith: "Partagé avec",

  myAdditions: "mes ajouts",
  myFavorites: "mes favories",
  mySpaces: "mes espaces",

  PUBLIC: "Tous",
  ALL_COLLABORATORS: "Tous les collaborateurs",
  ALL_CLIENTS: "Tous les clients",
  ALL_CONTACTS: "Tous les contacts",
  SPEC_COLLABORATOR: "Collaborateurs spécifique",
  SPEC_CLIENT: "Clients spécifique",
  SPEC_CONTACT: "Contact spécifique",
  GROUP: "Groupe",

  PUBLIC_SCOPE: "Public",
  ALL_COLLABORATORS_SCOPE: "Collaborateurs",
  ALL_CLIENTS_SCOPE: "Clients",
  ALL_CONTACTS_SCOPE: "Contacts",
  SPEC_COLLABORATOR_SCOPE: "Spec Collabs",
  SPEC_CLIENT_SCOPE: "Spec Clients",
  SPEC_CONTACT_SCOPE: "Spec Contact",
  GROUP_SCOPE: "Groupe",

  chain: "Chaîne",
  chain_title: "Titre de la chaîne",
  authors: "Auteurs",
  author_title: "Titre de l'auteur",
  author_headline: "Titre de l'author",
  add_chain: "Ajouter une chaîne",
  loading_suggestions: "Chargement...",
  type_chain: "Tapez le nom de la chaîne",
  type_author: "Tapez le nom de l'auteur",
  our_colleagues: "Nos collègues",
  webtools_shared_by_colleague: "Webtools partagés par les collègues",
  data_saved_successfully: "Données sauvegardées avec succès",
  webtools_rejected: "Webtools rejetés",
  pageNotFound: "Cette page n'a pas pu être trouvée!",
  getLost: "Tu as navigué si vite et tu t'es perdu...",

  // modal
  delete_confirmation_title: "Confirmation de suppression",
  delete_confirmation_text:
    "Êtes-vous sûr de bien vouloir supprimer cet élément ?",
  error: "Erreur",

  share_with: "Partager avec",

  draft: "Brouillon",
  published: "Publié",
  disabled: "Désactivé",

  select_category: "Sélectionner une catégorie",
  select_tags: "Sélectionner des mots clés",
  select_theme: "Sélectionner un thème",
  select_page: "Sélectionner une page",

  confirmAccept: "Confirmation de publication",
  confirmAcceptMessage: "Voulez-vous accepter cette demande?",
  confirmReject: "Confirmation de rejection",
  confirmRejectMessage: "Ete vous sûr de bien vouloir rejeter cette demande?",
  no_request_found: "Aucune demande trouvée",
  libraryFFF: "Bibliothèque FFF",
};
export default faqFr;
