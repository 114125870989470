import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteWebtool,
  getCategories,
  getFavorites,
  getWebtools,
  saveFavorite,
} from "../../api";
import { CardTool, ModalConfirm } from "tamtam-components";
import _ from "../../i18n";
import classnames from "classnames";
import styles from "./MySpace.module.scss";
import NotFound from "../Commun/NotFound/NotFound";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { SHARING_OPTIONS } from "../../config";
import stylesAddComponent from "../ManagementSpace/ManagementSpace.module.scss";
import AddWebtool from "../ManagementSpace/AddWebtool";

function MyAdditions() {
  const params = useSelector((state) => state.params);
  const { user, navCommunity, token } = useSelector((state) => state.auth);

  const [deletingWebtool, setDeletingWebtool] = useState(false);
  const [webtoolDeleted, setWebtoolDeleted] = useState(null);
  const [webtoolEdited, setWebtoolEdited] = useState(null);

  const [categories, setCategories] = useState(null);
  const [loadingFavoriteIds, setLoadingFavoriteIds] = useState([]);
  const [favoriteIds, setFavoriteIds] = useState([]);
  const prevFilterRef = useRef();

  const [myAdditionsWebtools, setMyAdditionsWebtools] = useState([]);
  const [categoriesTags, setCategoriesTags] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [filter, setFilter] = useState({
    lng: params?.language,
    categoryId: null,
    keyWord: "",
  });
  const lng = filter.lng?.charAt(0).toUpperCase() + filter.lng?.slice(1);

  const allowedActions =
    user &&
    (user.isUaAdmin ||
      (user.role.typeStatus === "LEGAL_REPRESENTATIVE" &&
        user.role.type === "MANAGER" &&
        user.role.organization.id === navCommunity.id));
  useQuery(["getFavorites", token, navCommunity], async () => {
    if (token && navCommunity) {
      try {
        const response = await getFavorites({
          token: token,
          filter,
          community: navCommunity.id,
        });
        let ids = response.data.data?.map((item) => item.id);
        setFavoriteIds(ids);
      } catch (e) {
        return null;
      }
    }
  });

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    prevFilterRef.current = filter;
  }, [filter]);

  async function fetchCategories(keyWord = "") {
    let filter = { search: keyWord, lng: params?.language };
    setCategories(null);
    await getCategories({ token: token, filter }).then((response) => {
      setCategories(response.data.data);
    });
  }

  const editWebTool = (webtool) => {
    setWebtoolEdited(webtool);
  };

  const deleteWebTool = (webtool) => {
    setWebtoolDeleted(webtool);
  };

  useEffect(() => {
    if (token) {
      fetchCategories();
    }
  }, [token]);

  const mAdditions = useQuery(
    ["getMyAddions", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const webtools = await getWebtools({
          token: token,
          community: navCommunity.id,
          filter: {
            creator: user.id,
          },
          workspace: "rss",
        })
          .then((res) => {
            return res.data.data ? res.data.data : [];
          })
          .catch((error) => {
            return [];
          });

        const arr = webtools
          .filter((w) => w[`title${lng}`]?.length > 0)
          .map((w) => w.category);
        const categories = arr.filter(
          (x, i) =>
            arr.findIndex((object) => {
              return object.id === x.id;
            }) === i
        );
        setCategoriesTags(categories);
        return webtools;
      }
    }
  );

  useEffect(() => {
    const webtools = mAdditions.data?.filter((webtool) => {
      let toReturn = true && webtool[`title${lng}`];
      if (filter.categoryId) {
        toReturn = webtool.category.id === filter.categoryId;
      }
      if (toReturn && filter.keyWord) {
        toReturn = webtool[`title${lng}`]
          .toLowerCase()
          .includes(filter.keyWord.toLowerCase());
      }
      return toReturn;
    });
    setMyAdditionsWebtools(webtools);
  }, [mAdditions.data, filter]);

  const updateLanguageFilter = (lang) => {
    setFilter((previousState) => {
      return { ...previousState, lng: lang, categoryId: null };
    });
  };

  const updateKeyWordFilter = (word) => {
    setFilter((previousState) => {
      return { ...previousState, keyWord: word.target.value };
    });
  };

  const updateCategoryFilter = (category) => {
    if (filter.categoryId === category.id) {
      category = { id: null };
    }
    setFilter((previousState) => {
      return { ...previousState, categoryId: category.id };
    });
  };

  const handleSaveFavorite = async (webtool) => {
    if (loadingFavoriteIds.includes(webtool.id)) {
      return;
    } else {
      let filtredLoading = [...loadingFavoriteIds, webtool.id];
      setLoadingFavoriteIds(filtredLoading);
    }
    try {
      await saveFavorite({
        id: webtool.id,
        token: token,
        organizationId: navCommunity.id,
      });
      if (favoriteIds.includes(webtool.id)) {
        let filtredFavorites = favoriteIds.filter((fav) => fav !== webtool.id);
        setFavoriteIds(filtredFavorites);
      } else {
        let filtredFavorites = [...favoriteIds, webtool.id];
        setFavoriteIds(filtredFavorites);
      }
      let filtredLoadingFavorites = loadingFavoriteIds.filter(
        (fav) => fav !== webtool.id
      );
      setLoadingFavoriteIds(filtredLoadingFavorites);
      toast.success(_("saveSuccess"));
    } catch (e) {
      toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const renderMySpaceFilter = () => {
    return (
      <div className={styles.filter}>
        <div className={classnames(styles.search_frame, "grid-x")}>
          {["fr", "en", "nl"].map((item, i) => {
            return (
              <div
                className={classnames(
                  styles.lng_item,
                  filter.lng === item ? styles.activated : ""
                )}
                onClick={() => updateLanguageFilter(item)}
              >
                {item}
              </div>
            );
          })}
          <div className={classnames(styles.search, "cell small-12 medium-5")}>
            <i className="icon icon-magnifier" />
            <input
              id="searchInput"
              type="text"
              value={filter.keyWord}
              placeholder={_("Search...")}
              onChange={(event) => updateKeyWordFilter(event)}
            />
          </div>
        </div>
        <div className={classnames(styles.tags, "grid-x")}>
          <div
            className={classnames(
              styles.tag_item,
              !filter.categoryId ? styles.activated : ""
            )}
            onClick={() => {
              setFilter((previousState) => {
                return { ...previousState, categoryId: null };
              });
            }}
          >
            {_("all")}
          </div>
          {categoriesTags.map((item, i) => {
            return (
              <div
                className={classnames(
                  styles.tag_item,
                  filter.categoryId === item.id ? styles.activated : ""
                )}
                style={{
                  borderColor: item.colorCode,
                  backgroundColor:
                    filter.categoryId === item.id ? item.colorCode : "",
                }}
                onClick={() => updateCategoryFilter(item)}
              >
                {item[`name${lng}`]}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const deleteCard = async () => {
    setDeletingWebtool(true);
    await deleteWebtool({ token: token, webtoolId: webtoolDeleted.id })
      .then(() => {
        setDeletingWebtool(true);
        mAdditions.refetch();
        toast.success(_("webToolDeleted"));
        setWebtoolDeleted(null);
      })
      .finally(() => {
        setDeletingWebtool(false);
      });
  };

  useEffect(() => {
    if (
      navCommunity &&
      !navCommunity.webtoolsPreferences?.source?.includes("MY_LIBRARY")
    ) {
      window.location.href = "/";
    }
  }, [navCommunity]);

  let cardsFetching = [];
  for (let j = 0; j < 8; j++) {
    cardsFetching.push(
      <div
        className="cell small-12 medium-4 large-3"
        style={{ marginTop: "20px" }}
      >
        <div key={j} className="card_tool_fetching gradiant" />
      </div>
    );
  }
  return (
    <div className={classnames(styles.my_space)}>
      <div className={styles.header}>
        <span>{_("myAdditions")}</span>
      </div>
      <div className={stylesAddComponent.content} style={{ padding: 0 }}>
        {/* <AddWebtool
          activeTab={"WEBTOOLS"}
          setWebtoolDeleted={setWebtoolDeleted}
          webtoolDeleted={webtoolDeleted}
          setWebtoolEdited={setWebtoolEdited}
          webtoolEdited={webtoolEdited}
          fetchCategories={fetchCategories}
          categories={categories}
          updateWebTools={mAdditions.refetch}
          hide={true}
        /> */}
        <AddWebtool
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setWebtoolDeleted={setWebtoolDeleted}
          webtoolDeleted={webtoolDeleted}
          setWebtoolEdited={setWebtoolEdited}
          webtoolEdited={webtoolEdited}
          fetchCategories={fetchCategories}
          categories={categories}
          updateWebTools={() => mAdditions.refetch()}
        />
      </div>
      {renderMySpaceFilter()}
      <div className={"grid-x"} style={{ marginTop: "6px" }}>
        {mAdditions.isFetching ? (
          cardsFetching
        ) : myAdditionsWebtools?.length === 0 ? (
          <NotFound />
        ) : (
          myAdditionsWebtools?.map((webtool) => {
            const organizationName =
              webtool.organizationSource &&
              webtool.organizationSource.abbreviation
                ? webtool.organizationSource.abbreviation
                : webtool.organizationSource && webtool.organizationSource.name
                ? webtool.organizationSource.name
                : webtool.organization.abbreviation
                ? webtool.organization.abbreviation
                : webtool.organization.name;
            return webtool[`title${lng}`]?.length > 0 ? (
              <div className="cell small-12 medium-6 large-3  grid_card">
                <CardTool
                  toolOptions={SHARING_OPTIONS}
                  data={{
                    id: webtool.id,
                    title: webtool[`title${lng}`],
                    link: webtool[`urlName${lng}`],
                    description: webtool[`description${lng}`] ?? "",
                    tag: webtool.category[`name${lng}`],
                    icon: webtool.icon?.label,
                    created: webtool.createdAt
                      ? `${_("createdThe")} ${webtool.createdAt}`
                      : "",
                    organization: organizationName
                      ? `${_("by")} ${organizationName}`
                      : "",
                    source: webtool.organizationSource ? _("libraryFFF") : null,
                  }}
                  allowed={allowedActions || webtool.creator.id === user.id}
                  allowedActions={{
                    favorite: true,
                    update: allowedActions || webtool.creator.id === user?.id,
                    delete: allowedActions || webtool.creator.id === user?.id,
                  }}
                  color={
                    webtool.category?.colorCode
                      ? webtool.category.colorCode
                      : "#29394D"
                  }
                  onReach={() => openInNewTab(webtool[`urlName${lng}`])}
                  // isLoadingFavorite={loadingFavoriteIds.includes(webtool.id)}
                  loadingActions={{
                    favorite: loadingFavoriteIds.includes(webtool.id),
                  }}
                  isFavorite={favoriteIds.includes(webtool.id)}
                  onAddFavorite={() => handleSaveFavorite(webtool)}
                  onUpdate={() => editWebTool(webtool)}
                  onDelete={() => deleteWebTool(webtool)}
                />
              </div>
            ) : (
              ""
            );
          })
        )}
      </div>
      <ModalConfirm
        type={"delete"}
        isOpen={webtoolDeleted}
        onCancel={() => setWebtoolDeleted(null)}
        onConfirm={() => deleteCard()}
        inProcess={deletingWebtool}
        title={_("confirmDelete")}
        text={_("confirmDeleteMessage")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </div>
  );
}

export default MyAdditions;
