import { TTP_WEBTOOL_URL, TTP_HOME_URL } from "../config";

export const getDefaultLanguage = () => {
  let lng = navigator.language || navigator.userLanguage;
  lng = lng.split("-")[0];
  return ["fr", "en", "nl"].includes(lng) ? lng : "en";
};

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const redirectToHome = (returnTo = "") => {
  const goto = encodeURIComponent(TTP_WEBTOOL_URL + "/" + returnTo);
  window.location.assign(`${TTP_HOME_URL}?gotoWithAuth=${goto}`);
};

export function validateUrl(url) {
  var re =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(String(url).toLowerCase());
}

export function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validateUen = (uen) => {
  const formattedUen = uen.replace(
    /(BE|be)? ?(\d{4})[. ]?(\d{3})[. ]?(\d{3})/,
    "BE $2.$3.$4"
  );
  return /^[a-zA-Z]{2} (\d{4})[. ]?(\d{3})[. ]?(\d{3})$/.test(formattedUen);
};

export function formatPhone(number) {
  if (!number) {
    return "";
  }
  if (number.substr(0, 3) === "+32") {
    let base = number.substr(3);
    let subStr = base.substr(0, 2);

    if (["1", "5", "6", "7", "8"].includes(base.substr(0, 1))) {
      return (
        "+32 (0)" +
        subStr +
        " " +
        base.substr(2, 2) +
        " " +
        base.substr(4, 2) +
        " " +
        base.substr(6, 2)
      );
    }
    if (
      ["2", "3"].includes(base.substr(0, 1)) ||
      ["42", "43", "90", "92", "93", "94", "97", "98", "99"].includes(subStr)
    ) {
      return (
        "+32 (0)" +
        base.substr(0, 1) +
        " " +
        base.substr(1, 3) +
        " " +
        base.substr(4, 2) +
        " " +
        base.substr(6, 2)
      );
    }
    if (["46", "47", "48", "49"].includes(base.substr(0, 1))) {
      return (
        "+32 (0)" +
        base.substr(0, 3) +
        " " +
        base.substr(3, 2) +
        " " +
        base.substr(5, 2) +
        " " +
        base.substr(7, 2)
      );
    }
    if (base.substr(0, 3) === "800" || subStr === "90") {
      return (
        "+32 (0)" +
        base.substr(0, 3) +
        " " +
        base.substr(3, 2) +
        " " +
        base.substr(5, 3)
      );
    }
  } else if (number.substr(0, 3) === "+33") {
    let base = number.substr(3);
    return (
      "+33 (0)" +
      base.substr(0, 1) +
      " " +
      base.substr(1, 2) +
      " " +
      base.substr(3, 2) +
      " " +
      base.substr(5, 2) +
      " " +
      base.substr(7, 2)
    );
  }
  return number;
}
export const getDateLabel = (date) => {
  const d = new Date(date);

  const result = d.toDateString().split(" ");

  const hours =
    parseInt(d.getHours(), 10) < 10 ? "0" + d.getHours() : d.getHours();
  const minutes =
    parseInt(d.getMinutes(), 10) < 10 ? "0" + d.getMinutes() : d.getMinutes();

  return (
    result[2] +
    " " +
    result[1] +
    " " +
    result[3] +
    ", at " +
    hours +
    ":" +
    minutes
  );
};

export const sanitize = function (str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeeiiiiooooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export function sortCategoriesAlphabetically(items, key) {
  return items.slice().sort((a, b) => {
    return a[key].toLowerCase().localeCompare(b[key]);
  });
}

export const addLandaSize = (img, width = 0, height = 0) => {
  let result = img;
  let found = false;
  if (!img) {
    return "";
  }
  const splt = img.split(".");
  const ext = splt[splt.length - 1];

  if (width > 0) {
    result += `/w${width}`;
    found = true;
  }
  if (height > 0) {
    const sep = width > 0 ? "-" : "/";
    result += `${sep}h${height}`;
    found = true;
  }
  result += found ? "-noEnlarge" : "/noEnlarge";

  return `${result}.${ext}`.replace(
    "https://s3.eu-west-1.amazonaws.com/tamtam",
    "https://s3.tamtam.pro"
  );
};

function extractFirstLettre(arrayStr, length) {
  let result = "";
  for (let i = 0; i < arrayStr.length; i++) {
    if (arrayStr[i]) {
      result += arrayStr[i].substring(0, 1);
    }
  }
  return result.toUpperCase();
}

export const getUserNameForAvatar = (firstName, lastName) => {
  let fName = firstName.split(" ");
  if (fName.length >= 3) {
    return extractFirstLettre(fName, 3);
  } else {
    let lName = lastName.split(" ");
    return extractFirstLettre(fName.concat(lName), 3);
  }
};

export function getUsersInfos(data) {
  let result = data.map((user) => {
    let usr = user.defaultSignature
      ? {
          ...user,
          title: user.defaultSignature.title,
          header: user.defaultSignature.header,
        }
      : user.role
      ? {
          ...user,
          title: user.firstName + " " + user.lastName,
          header: user.role.function,
        }
      : { ...user, title: user.firstName + " " + user.lastName };
    return { ...usr, isAuthor: true };
  });
  return result;
}

export function getAuthorAllHeadlines(newAuthor) {
  let headlines =
    newAuthor.blogRoleInOrganization &&
    newAuthor.blogRoleInOrganization[0] &&
    newAuthor.blogRoleInOrganization[0].headlines &&
    typeof newAuthor.blogRoleInOrganization[0].headlines === "object"
      ? newAuthor.blogRoleInOrganization[0].headlines
      : null;

  if (!headlines) {
    headlines = newAuthor.author;
  }
  return headlines;
}

export function getAuthorHeadlines(newAuthor, selectedLanguage) {
  const headlineAttr = `headline${
    selectedLanguage.charAt(0).toUpperCase() + selectedLanguage.slice(1)
  }`;

  let headlines =
    newAuthor.blogRoleInOrganization &&
    newAuthor.blogRoleInOrganization[0] &&
    newAuthor.blogRoleInOrganization[0][headlineAttr] &&
    newAuthor.blogRoleInOrganization[0][headlineAttr].title
      ? newAuthor.blogRoleInOrganization[0][headlineAttr].title
      : "";
  if (
    !headlines &&
    newAuthor.author &&
    newAuthor.author[headlineAttr] &&
    newAuthor.author[headlineAttr].title
  ) {
    headlines = newAuthor.author[headlineAttr].title;
  }

  return headlines;
}

export function cardsFetching(number) {
  let cardsFetching = [];
  for (let j = 0; j < number; j++) {
    cardsFetching.push(<div key={j} className="card_tool_fetching gradiant" />);
  }
  return cardsFetching;
}

export const getTagName = (tag, currentLanguage) => {
  let languages = ["nameEn", "nameFr", "nameNl"].filter(
    (e) => e !== currentLanguage
  );

  for (let i = 0; i < languages.length; i++) {
    let lng = languages[i];

    if (tag[lng] != null && tag[lng].trim() !== "") {
      return tag[lng];
    }
  }
  return "";
};

export const formattedTags = (tags, language) => {
  const formattedTags = [];
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  tags.forEach((tag) => {
    if (tag["__isNew__"]) {
      let newTag = {};
      newTag[nameAttr] = tag.value;
      formattedTags.push(newTag);
    } else {
      formattedTags.push({ id: tag.value });
    }
  });
  return formattedTags;
};
