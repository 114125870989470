import axios from "axios";
import { TTP_API_URL } from "../config";
import {
  getRequestCancellationToken,
  getRequestConfig,
  throwCatchedError,
} from "../utils";

let getRequestsCTS;

export const getWebtoolIcons = (token) => {
  const sort = [
    {
      property: "label",
      dir: "asc",
    },
  ];
  const fields = ["*", "tags"];
  const requestUrl = `${TTP_API_URL}/media/webtool-icon`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      sort: JSON.stringify(sort),
      fields: fields.join(","),
      limit: 500,
      start: 0, //offset
    },
  });
};

export const saveWebtool = ({
  token,
  lng,
  id,
  organization,
  category,
  titleFr = "",
  titleEn = "",
  titleNl = "",
  descriptionFr = "",
  descriptionEn = "",
  descriptionNl = "",
  urlFr = "",
  urlEn = "",
  urlNl = "",
  tags,
  creator,
  icon = null,
  onProgress,
  languages,
  csScope = "PUBLIC",
  specCollaborators,
  specClients,
  specContacts,
  groups,
  theme,
  pages,
  chain,
  canBeShared,
  fffLibrary,
  status,
  authors,
}) => {
  const requestUrl = `${TTP_API_URL}/media/webtool`;
  let config = {};
  let scopes = [];
  if (onProgress) {
    config.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress(percentCompleted);
    };
  }

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("organization", organization.id);
  formData.append("theme", theme.id);
  formData.append("canBeShared", canBeShared ? 1 : 0);
  formData.append("fffLibrary", fffLibrary ? "PROPOSED" : "");
  formData.append("status", status);

  id && formData.append("id", id);
  icon && formData.append("icon", icon.id);
  category && formData.append("category", category.value || category.id);
  languages && formData.append("languages", languages.join(","));
  creator && creator && formData.append("creator", creator.id);

  titleFr && formData.append("titleFr", titleFr);
  titleNl && formData.append("titleNl", titleNl);
  titleEn && formData.append("titleEn", titleEn);

  descriptionFr && formData.append("descriptionFr", descriptionFr);
  descriptionNl && formData.append("descriptionNl", descriptionNl);
  descriptionEn && formData.append("descriptionEn", descriptionEn);

  urlFr && formData.append("urlNameFr", urlFr);
  urlNl && formData.append("urlNameNl", urlNl);
  urlEn && formData.append("urlNameEn", urlEn);

  groups && formData.append("groups", JSON.stringify(groups));
  specClients && formData.append("specClients", JSON.stringify(specClients));
  specContacts && formData.append("specContacts", JSON.stringify(specContacts));

  if (chain) {
    formData.append("chain", chain.id);
  }
  if (specCollaborators) {
    formData.append("specCollaborators", JSON.stringify(specCollaborators));
  } else {
    scopes.push("SPEC_COLLABORATORS");
  }

  csScope &&
    formData.append(
      "csScope",
      csScope.filter((sc) => !scopes.includes(sc)).join(",")
    );

  if (tags && tags.length > 0) {
    tags.forEach((tag, i) => {
      if (tag.id) {
        formData.append(`tags[${i}][id]`, tag.id);
      } else {
        formData.append(`tags[${i}][nameFr]`, tag["nameFr"] || "");
        formData.append(`tags[${i}][nameEn]`, tag["nameEn"] || "");
        formData.append(`tags[${i}][nameNl]`, tag["nameNl"] || "");
      }
    });
  }
  if (pages && pages.length > 0) {
    pages.forEach((page, i) => {
      formData.append(`pages[${i}]`, page.id);
    });
  }

  if (authors && authors.length > 0) {
    let userIds = [];
    let users = authors.filter((user) => {
      const result =
        user.isAuthor === true &&
        user.enableAvatar !== "D" &&
        userIds.indexOf(user.id) === -1;
      if (result) {
        userIds.push(user.id);
      }
      return result;
    });

    if (users && users.length > 0) {
      users.forEach((author, i) => {
        formData.append(
          `webtoolBlogRole[${i}][signature][title]`,
          author.signature.title
        );

        if (
          author.signature.head &&
          author.signature.head !== undefined &&
          author.signature.head.trim() !== "undefined"
        ) {
          formData.append(
            `webtoolBlogRole[${i}][signature][head]`,
            author.signature.head
          );
        } else {
          formData.append(`webtoolBlogRole[${i}][signature][head]`, "");
        }

        formData.append(
          `webtoolBlogRole[${i}][enableAvatar]`,
          author.enableAvatar ? 1 : 0
        );
        formData.append(`webtoolBlogRole[${i}][priority]`, author.priority);
        formData.append(`webtoolBlogRole[${i}][action]`, "WRITE");
        formData.append(`webtoolBlogRole[${i}][user]`, author.id);
        if (author.status) {
          formData.append(`webtoolBlogRole[${i}][status]`, author.status);
        }
      });
    }
  }

  return axios.post(requestUrl, formData, config);
};

export const deleteWebtool = ({ token, webtoolId }) => {
  const requestUrl = `${TTP_API_URL}/media/webtool/${webtoolId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const getFavorites = ({ token, community, filter, workspace }) => {
  const language = filter?.lng
    ? filter.lng?.charAt(0).toUpperCase() + filter.lng?.slice(1)
    : "Fr";

  const filters = [
    {
      property: "organization.id",
      value: community,
      operator: "eq",
    },
  ];
  if (filter?.categoryId) {
    filters.push({
      property: "category.id",
      value: filter.categoryId,
      operator: "eq",
    });
  }

  if (filter?.search) {
    filters.push({
      property: `title${language}`,
      value: "%" + filter.search + "%",
      operator: "like",
    });
  }

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];
  const fields = [
    "*",
    "social",
    "creator",
    "organization",
    "organizationSource",
    "category",
    "theme",
    "pages",
    "tags",
    "icon",
  ];
  const requestUrl = `${TTP_API_URL}/media/webtool/favorites`;

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        filter: JSON.stringify(filters),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        workspace: "rss",
        //workspace: workspace ? workspace : null,
        limit: 200,
        start: 0, //offset
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getWebtools = ({
  token,
  community,
  userId,
  filter,
  limit,
  page,
  workspace,
  language,
}) => {
  const titleAttr =
    `title${language?.charAt(0).toUpperCase()}` + language?.slice(1);

  const filters = [];

  if (community) {
    filters.push({
      property: "organization.id",
      value: community,
      operator: "eq",
    });
  }

  if (filter?.origin) {
    filters.push({
      property: "organizationSource",
      value: null,
      operator: "null",
    });
  }

  if (filter?.notOrgId) {
    filters.push({
      property: "organization.id",
      value: filter.notOrgId,
      operator: "neq",
    });
  }

  if (filter?.canBeShared) {
    filters.push({
      property: "canBeShared",
      value: filter.canBeShared,
      operator: "eq",
    });
  }

  if (filter?.bannedIds?.length > 0) {
    filters.push({
      property: "id",
      value: filter.bannedIds,
      operator: "nin",
    });
  }
  if (filter?.scScope) {
    filters.push({
      property: "csScope",
      value: filter.scScope,
      operator: "eq",
    });
  }

  if (filter?.categoryId) {
    filters.push({
      property: "category.id",
      value: filter.categoryId,
      operator: "eq",
    });
  }
  if (filter?.search) {
    filters.push({
      property: titleAttr,
      value: "%" + filter.search + "%",
      operator: "like",
    });
  }

  if (filter?.creator) {
    filters.push({
      property: "creator",
      value: filter.creator,
      operator: "eq",
    });
  }

  if (userId) {
    filters.push({
      property: "creator",
      value: userId,
      operator: "eq",
    });
  }

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];
  const fields = [
    "*",
    "social",
    "creator",
    "organization",
    "organizationSource",
    "category",
    "theme",
    "pages",
    "tags",
    "icon",
    "chain",
    "authors",
  ];
  const requestUrl = `${TTP_API_URL}/media/webtool`;
  const lmt = limit ?? 200;
  const params = workspace
    ? {
        access_token: token,
        filter: JSON.stringify(filters),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        limit: lmt,
        workspace,
        start: page ? lmt * page - lmt : 0, //offset
      }
    : {
        access_token: token,
        filter: JSON.stringify(filters),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        limit: lmt,
        start: page ? lmt * page - lmt : 0, //offset
      };
  return axios
    .get(requestUrl, {
      params: params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getFFFLibrary = ({
  token,
  community,
  userId,
  filter,
  workspace,
}) => {
  let language = filter.lng?.charAt(0).toUpperCase() + filter.lng?.slice(1);

  let filters = [];

  if (filter.status === "ALL") {
    filters.push({
      property: "fffLibrary",
      value: ["PROPOSED", "ACCEPTED", "REJECTED"],
      operator: "in",
    });
  } else {
    filters.push({
      property: "fffLibrary",
      value: filter.status,
      operator: "eq",
    });
  }
  if (filter.keyword) {
    filters.push({
      property: `title${language}`,
      value: "%" + filter.keyword + "%",
      operator: "like",
    });
  }

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];
  const fields = [
    "*",
    "social",
    "creator",
    "organization",
    "organizationSource",
    "category",
    "theme",
    "pages",
    "tags",
    "icon",
    "chain",
  ];
  const requestUrl = `${TTP_API_URL}/media/webtool`;
  const params = workspace
    ? {
        access_token: token,
        filter: JSON.stringify(filters),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        limit: 200,
        workspace,
        start: 0, //offset
      }
    : {
        access_token: token,
        filter: JSON.stringify(filters),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        limit: 200,
        start: 0, //offset
      };
  return axios
    .get(requestUrl, {
      params: params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getlibraryWebtools = ({ token, community }) => {
  const filters = [
    {
      property: "status",
      value: "PUBLISHED",
      operator: "eq",
    },
  ];

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];
  const fields = [
    "*",
    "social",
    "creator",
    "organization",
    "organizationSource",
    "category",
    "theme",
    "pages",
    "tags",
    "icon",
  ];
  const requestUrl = `${TTP_API_URL}/media/webtool/get-library-webtools`;

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        filter: JSON.stringify(filters),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        organization_id: community,
        // workspace: "rss",
        limit: 200,
        start: 0, //offset
        from_app: "webtools",
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const saveBannedWebtool = ({ token, id, action, organizationId }) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/save-banned-preferences`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("appName", "webtools");
  formData.append("id", id);
  formData.append("action", action);
  formData.append("organizationId", organizationId);

  return axios.post(requestUrl, formData);
};

export const saveFavorite = ({ token, id, organizationId }) => {
  const requestUrl = `${TTP_API_URL}/media/favorite`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("webtool", id);
  formData.append("organization", organizationId);

  return axios.post(requestUrl, formData);
};

export const unshareWebtool = ({ token, webtool, organization }) => {
  const requestUrl = `${TTP_API_URL}/media/organization-webtool/${webtool}`;

  let formData = new FormData();
  formData.append("access_token", token);
  // formData.append("webtool", webtool);
  // formData.append("organization", organization);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const getThemes = ({ token }) => {
  const requestUrl = `${TTP_API_URL}/blog/theme`;
  const filters = [
    {
      property: "isDefault",
      value: 0,
      operator: "eq",
    },
  ];
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields: "*,mediaThemes,pages",
      limit: 200,
      start: 0,
    },
  });
};

export const getRecommended = ({ token, community }) => {
  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];
  const fields = [
    "*",
    "social",
    "creator",
    "organization",
    "organizationSource",
    "category",
    "theme",
    "pages",
    "tags",
    "icon",
  ];
  const requestUrl = `${TTP_API_URL}/media/webtool/recommended/${community}`;

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        limit: 200,
        start: 0,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const updateRequestStatus = ({ token, id, status }) => {
  const requestUrl = `${TTP_API_URL}/blog/fff-library-request`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", id);
  formData.append("status", status);

  return axios.post(requestUrl, formData);
};

export const namageWebtool = ({ token, id, action }) => {
  const requestUrl = `${TTP_API_URL}/media/webtool/fff-library`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", id);
  formData.append("action", action);

  return axios.post(requestUrl, formData);
};

export const saveFFFLibraryRequest = (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/fff-library-request`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("objectId", data.objectId);
  formData.append("objectType", "WEBTOOL");
  formData.append("status", "PENDING");
  formData.append("action", data.action);
  formData.append("content", data.content);
  formData.append("organization", data.organization);

  return axios.post(requestUrl, formData, {});
};

export const getFFFLibraryRequests = (
  token,
  status,
  cancellationTokenSource = null
) => {
  let requestCancellationToken = getRequestCancellationToken(
    getRequestsCTS,
    cancellationTokenSource
  );

  let sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  let filter = [
    {
      property: "objectType",
      value: "WEBTOOL",
      operator: "eq",
    },
  ];

  if (status !== "ALL") {
    filter.push({
      property: "status",
      value: status,
      operator: "eq",
    });
  }

  const fields = ["*", "webtool"];
  const requestUrl = `${TTP_API_URL}/blog/fff-library-request`;

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    fields: fields.join(","),
    nolimit: 1,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getWebtool = (token, id) => {
  const fields = [
    "*",
    "social",
    "creator",
    "organization",
    "organizationSource",
    "category",
    "theme",
    "pages",
    "tags",
    "icon",
    "chain",
    "authors",
  ];
  const requestUrl = `${TTP_API_URL}/media/webtool`;
  const filter = [{ property: "id", value: id, operator: "eq" }];
  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        filter: JSON.stringify(filter),
        fields: fields.join(","),
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};
