import { CardTool, ModalConfirm } from "tamtam-components";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "../../i18n";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import {
  getFFFLibrary,
  getFFFLibraryRequests,
  getWebtool,
  namageWebtool,
  updateRequestStatus,
} from "../../api";

import NotFound from "../Commun/NotFound/NotFound";
import Tooltip from "../Library/Tooltip";

import IconClose from "components/Icons/IconClose";
import IconCheck from "components/Icons/IconCheck";
import FilterComponent from "./FilterComponent";
import { cardsFetching } from "utils";
import { debounce } from "lodash";
import classnames from "classnames";
import styles from "./ManageRequests.module.scss";
import ArrowDownIcon from "components/Icons/ArrowDownIcon";
import CloseIcon from "components/Icons/CloseIcon";
import IconPen from "components/Icons/IconPen";
import AddWebtool from "./AddWebtool";
import IconCircleLoader from "components/Icons/IconCircle";
import { current } from "@reduxjs/toolkit";

const ManageRequests = () => {
  const { token } = useSelector((state) => state.auth);
  const params = useSelector((state) => state.params);
  const [tooltipIsVisible, setVisibilityTooltip] = useState(null);
  const [requestWebtooles, setRequestWebtooles] = useState([]);
  const [isFetching, setIsFeching] = useState(false);
  const [rejectingWebtool, setRejectingWebtool] = useState(false);
  const [rejectedWebtool, setRejectedWebtool] = useState(null);
  const [acceptingWebtool, setAcceptingWebtool] = useState(false);
  const [acceptedWebtool, setAcceptedWebtool] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [webtoolEdited, setWebtoolEdited] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isOpenAcceptModal, setIsOpenAcceptModal] = useState(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);

  const [filter, setFilter] = useState({
    lng: params?.language,
    status: "ALL",
    keyword: "",
  });

  const lang = filter.lng.charAt(0).toUpperCase() + filter.lng.slice(1);
  const titleAttr = `title${lang}`;
  const nameAttr = `name${lang}`;
  const urlNameAttr = `urlName${lang}`;
  const descAttr = `urlName${lang}`;

  const fetchRequests = async () => {
    setIsFeching(true);
    getFFFLibraryRequests(token, filter.status)
      .then(({ data }) => {
        setRequestWebtooles(data.data);
      })
      .catch((err) => {
        setRequestWebtooles([]);
      })
      .finally(() => {
        setIsFeching(false);
      });
  };

  const fetchWebtool = async (id) => {
    setIsLoading(true);
    setCurrentId(id);
    getWebtool(token, id)
      .then(({ data }) => {
        if (data.data?.length === 1) {
          setWebtoolEdited(data.data[0]);
          setIsOpen(true);
        } else {
          setWebtoolEdited(null);
          setIsOpen(false);
        }
        // setRequestWebtooles(data.data);
      })
      .catch((err) => {
        // setRequestWebtooles([]);
      })
      .finally(() => {
        setIsLoading(false);
        setCurrentId(null);
      });
  };

  const handleKeywordChange = (e) => {
    setFilter({ ...filter, keyword: e.target.value });
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleKeywordChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    fetchRequests();
  }, [filter]);

  // const allowedActions =
  //   user &&
  //   (user.isUaAdmin ||
  //     (user.role.typeStatus === "LEGAL_REPRESENTATIVE" &&
  //       user.role.type === "MANAGER" &&
  //       user.role.organization.id === navCommunity.id));

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleRejectWebtool = async () => {
    setRejectingWebtool(true);
    await updateRequestStatus({
      token: token,
      id: currentId,
      status: "REJECTED",
    })
      .then(() => {
        fetchRequests();
        toast.success(_("data_saved_successfully"));
      })
      .catch((e) => {
        toast.error(_("savingError"), { autoClose: true });
      })
      .finally(() => {
        setRejectingWebtool(false);
        setIsOpenRejectModal(false);
        setCurrentId(null);
      });
  };

  const handleAcceptWebtool = async () => {
    setAcceptingWebtool(true);
    await updateRequestStatus({
      token: token,
      id: currentId,
      status: "ACCEPTED",
    })
      .then(() => {
        fetchRequests();
        toast.success(_("data_saved_successfully"));
      })
      .catch((e) => {
        toast.error(_("savingError"), { autoClose: true });
      })
      .finally(() => {
        setAcceptingWebtool(false);
        setIsOpenAcceptModal(false);
        setCurrentId(null);
      });
  };

  return (
    <>
      <AddWebtool
        hideAddButton={true}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setWebtoolEdited={setWebtoolEdited}
        webtoolEdited={webtoolEdited}
      />
      <FilterComponent
        filter={filter}
        statuses={["ALL", "PENDING", "ACCEPTED", "REJECTED"]}
        updateStatusFilter={(status) => {
          setFilter((previousState) => {
            return { ...previousState, status: status };
          });
        }}
      />
      <div className={styles.items}>
        <div className={classnames(styles.item, styles.item_head)}>
          <div className={styles.item_top}>
            <div className={styles.item_date}>Date</div>
            <div className={styles.item_title}>Webtool title</div>
            <div className={styles.item_type}>Action</div>
            <div className={styles.item_status}>Status</div>
            <div className={styles.item_actions}></div>
          </div>
        </div>
        {isFetching ? (
          <div>
            {[...Array(4)].map((el) => (
              <Skeleton width="100%" height={80} />
            ))}
          </div>
        ) : requestWebtooles.length === 0 ? (
          <div className={styles.noData}>{_("no_request_found")}</div>
        ) : (
          <>
            {requestWebtooles
              .filter((r) => r.webtool)
              .map((item, index) => {
                const title =
                  item.webtool[titleAttr] ||
                  item.webtool["titleFr"] ||
                  item.webtool["titleEn"] ||
                  item.webtool["titleNl"];
                return (
                  <div
                    key={item.id}
                    className={classnames(
                      styles.item,
                      activeItem === item.id && styles.active
                    )}
                  >
                    <div className={styles.item_top}>
                      <div className={styles.item_date}>
                        {moment(item.createdAt, ["YYYY-MM-DD HH:mm:ss"]).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                      <div className={styles.item_title}>{title}</div>
                      <div className={styles.item_type}>{item.action}</div>
                      <div
                        className={classnames(
                          styles.item_status,
                          styles[item.status.toLowerCase()]
                        )}
                      >
                        {item.status}
                      </div>
                      <div className={styles.item_actions}>
                        {item.status === "PENDING" && (
                          <>
                            {item.action === "EDIT" && (
                              <button
                                className={styles.action}
                                onClick={() => {
                                  fetchWebtool(item.webtool.id);
                                }}
                              >
                                {isLoading && currentId === item.webtool.id ? (
                                  <IconCircleLoader />
                                ) : (
                                  <i className="icon-ttp-edit"></i>
                                )}
                              </button>
                            )}
                            {(item.action === "EDIT" ||
                              item.action === "DELETE") && (
                              <>
                                <button
                                  className={styles.action}
                                  onClick={() => {
                                    setCurrentId(item.id);
                                    setIsOpenAcceptModal(true);
                                  }}
                                >
                                  <i className="icon-ttp-check"></i>
                                </button>
                                <button
                                  className={styles.action}
                                  onClick={() => {
                                    setCurrentId(item.id);
                                    setIsOpenRejectModal(true);
                                  }}
                                >
                                  <i className="icon-ttp-close"></i>
                                </button>
                              </>
                            )}
                          </>
                        )}

                        <button
                          className={styles.action}
                          onClick={() => {
                            setActiveItem(
                              activeItem === item.id ? null : item.id
                            );
                          }}
                          title="Detail"
                        >
                          <i className="icon-ttp-chevron-down"></i>
                        </button>
                      </div>
                    </div>

                    {item.content && (
                      <div className={styles.content}>
                        <h3 className={styles.content_title}>Message</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                );
              })}
            {/* </div> */}
          </>
        )}
      </div>
      <ModalConfirm
        type={"delete"}
        isOpen={isOpenRejectModal}
        onCancel={() => setIsOpenRejectModal(false)}
        onConfirm={handleRejectWebtool}
        inProcess={rejectingWebtool}
        title={_("confirmReject")}
        text={_("confirmRejectMessage")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      <ModalConfirm
        type={"publish"}
        isOpen={isOpenAcceptModal}
        onCancel={() => setIsOpenAcceptModal(false)}
        onConfirm={handleAcceptWebtool}
        inProcess={acceptingWebtool}
        title={_("confirmAccept")}
        text={_("confirmAcceptMessage")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </>
  );
};
export default ManageRequests;
