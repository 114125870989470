import { CardTool, ModalConfirm } from "tamtam-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import _ from "../../i18n";

import {
  deleteWebtool,
  getCategories,
  getFavorites,
  getWebtools,
  saveFavorite,
} from "../../api";

import NotFound from "../Commun/NotFound/NotFound";
import Tooltip from "../Library/Tooltip";
import FilterComponent from "./FilterComponent";
import AddWebtool from "./AddWebtool";

function ManageWebtools() {
  const { user, navCommunity, token } = useSelector((state) => state.auth);
  const params = useSelector((state) => state.params);
  const [favoriteIds, setFavoriteIds] = useState([]);
  const [tooltipIsVisible, setVisibilityTooltip] = useState(null);
  const [loadingFavoriteIds, setLoadingFavoriteIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [webtools, setWebtools] = useState(null);
  const [webtoolDeleted, setWebtoolDeleted] = useState(null);
  const [webtoolEdited, setWebtoolEdited] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash === "#open-form") {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    const timeoutId = setTimeout(() => {
      window.history.replaceState({}, document.title, window.location.pathname);
    }, 1000);

    // Initial check
    handleHashChange();

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      clearTimeout(timeoutId);
    };
  }, []);

  const [filter, setFilter] = useState({
    lng: params?.language,
    categoryId: null,
    keyWord: "",
  });

  const fetchAllCategories = async (keyWord = "") => {
    let filter = { search: keyWord, lng: params?.language };
    setCategories(null);
    await getCategories({ token: token, filter }).then((response) => {
      setCategories(response.data.data);
    });
  };

  const deleteCard = () => {
    deleteWebtool({ token: token, webtoolId: webtoolDeleted.id }).then(
      (response) => {
        webtoolsQuery.refetch();
        toast.success(_("webToolDeleted"));
        setWebtoolDeleted(null);
      }
    );
  };

  useQuery(["getFavorites", token, navCommunity], async () => {
    if (token && navCommunity) {
      try {
        const response = await getFavorites({
          token: token,
          filter,
          community: navCommunity.id,
        });
        let ids = response.data.data?.map((item) => item.id);
        setFavoriteIds(ids);
      } catch (e) {
        return null;
      }
    }
  });

  let webtoolsQuery = useQuery(
    ["getManagerWebtools", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const webtools = await getWebtools({
          token: token,
          community: navCommunity.id,
          filter: { lng: filter.lng },
          workspace: "rss",
        })
          .then((res) => {
            return res.data.data ? res.data.data : [];
          })
          .catch(() => {
            return [];
          });
        const lang = filter.lng.charAt(0).toUpperCase() + filter.lng.slice(1);
        const arr = webtools
          .filter((w) => w[`title${lang}`]?.length > 0)
          .map((w) => w.category);
        const categories = arr.filter(
          (x, i) =>
            arr.findIndex((object) => {
              return object.id === x.id;
            }) === i
        );
        setCategories(categories);
        return webtools;
      }
    }
  );

  useEffect(() => {
    if (webtoolsQuery.data) {
      const lang = filter.lng.charAt(0).toUpperCase() + filter.lng.slice(1);
      const data = webtoolsQuery.data.filter((webtool) => {
        let selected = false;
        if (filter.lng) {
          selected = webtool[`title${lng}`] !== "";
        }
        if (filter.lng && filter.keyWord) {
          selected =
            webtool[`title${lang}`] !== "" &&
            webtool[`title${lang}`]
              .toLowerCase()
              .includes(filter.keyWord.toLowerCase());
        }
        if (filter.categoryId) {
          selected = webtool.category.id === filter.categoryId;
        }
        return selected;
      });

      setWebtools(data);
    }
  }, [webtoolsQuery.data, filter]);

  const allowedActions =
    user &&
    (user.isUaAdmin ||
      (user.role.typeStatus === "LEGAL_REPRESENTATIVE" &&
        user.role.type === "MANAGER" &&
        user.role.organization.id === navCommunity.id));

  const lng = filter.lng.charAt(0).toUpperCase() + filter.lng.slice(1);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleSaveFavorite = async (webtool) => {
    if (loadingFavoriteIds.includes(webtool.id)) {
      return;
    } else {
      let filtredLoading = [...loadingFavoriteIds, webtool.id];
      setLoadingFavoriteIds(filtredLoading);
    }
    try {
      await saveFavorite({
        id: webtool.id,
        token: token,
        organizationId: navCommunity.id,
      });
      if (favoriteIds.includes(webtool.id)) {
        let filtredFavorites = favoriteIds.filter((fav) => fav !== webtool.id);
        setFavoriteIds(filtredFavorites);
      } else {
        let filtredFavorites = [...favoriteIds, webtool.id];
        setFavoriteIds(filtredFavorites);
      }
      let filtredLoadingFavorites = loadingFavoriteIds.filter(
        (fav) => fav !== webtool.id
      );
      setLoadingFavoriteIds(filtredLoadingFavorites);
      toast.success(_("saveSuccess"));
    } catch (e) {
      toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  let cardsFetching = [];
  for (let j = 0; j < 8; j++) {
    cardsFetching.push(
      <div
        className="cell small-12 medium-4 large-3"
        style={{ padding: "15px 15px 0 0px" }}
      >
        <div key={j} className="card_tool_fetching gradiant" />
      </div>
    );
  }
  return (
    <>
      <AddWebtool
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setWebtoolDeleted={setWebtoolDeleted}
        webtoolDeleted={webtoolDeleted}
        setWebtoolEdited={setWebtoolEdited}
        webtoolEdited={webtoolEdited}
        fetchCategories={fetchAllCategories}
        categories={categories}
        updateWebTools={() => webtoolsQuery.refetch()}
      />
      <FilterComponent
        filter={filter}
        categories={categories}
        updateKeyWordFilter={(e) => {
          setFilter((previousState) => {
            return { ...previousState, keyWord: e.target.value };
          });
        }}
        updateLanguageFilter={(lng) => {
          setFilter((previousState) => {
            return { ...previousState, lng };
          });
        }}
        updateCategoryFilter={(category) => {
          setFilter((previousState) => {
            return { ...previousState, categoryId: category.id };
          });
        }}
      />

      <div className="grid-x">
        {webtoolsQuery.isFetching ? (
          cardsFetching
        ) : webtools?.length === 0 ? (
          <NotFound />
        ) : (
          webtools?.map((webtool) => {
            const organizationName =
              webtool.organizationSource &&
              webtool.organizationSource.abbreviation
                ? webtool.organizationSource.abbreviation
                : webtool.organizationSource && webtool.organizationSource.name
                ? webtool.organizationSource.name
                : webtool.organization.abbreviation
                ? webtool.organization.abbreviation
                : webtool.organization.name;
            return webtool[`title${lng}`]?.length > 0 ? (
              <div
                className="cell small-12 medium-4 large-3"
                style={{ padding: "20px 15px 0 0px" }}
              >
                <CardTool
                  toolContent={
                    <Tooltip
                      webtool={webtool}
                      onSavedWebtool={setVisibilityTooltip}
                    />
                  }
                  displayTooltip={tooltipIsVisible === webtool.id}
                  setTooltipVisibility={(element) => {
                    setVisibilityTooltip(element ? webtool.id : null);
                  }}
                  data={{
                    id: webtool.id,
                    title: webtool[`title${lng}`],
                    link: webtool[`urlName${lng}`],
                    description: webtool[`description${lng}`] ?? "",
                    tag: webtool.category[`name${lng}`],

                    icon: webtool.icon?.label,
                    created: webtool.createdAt
                      ? `${_("createdThe")} ${webtool.createdAt}`
                      : "",
                    organization: organizationName
                      ? `${_("by")} ${organizationName}`
                      : "",
                    source: webtool.organizationSource ? _("libraryFFF") : null,
                  }}
                  allowed={allowedActions || webtool.creator.id === user.id}
                  allowedActions={{
                    share: false,
                    favorite: true,
                    // delete:
                    //   (allowedActions || webtool.creator.id === user.id) &&
                    //   webtool.organizationSourceId !== null,
                    update: allowedActions || webtool.creator.id === user.id,
                    delete: allowedActions || webtool.creator.id === user.id,
                  }}
                  loadingActions={{
                    favorite: loadingFavoriteIds.includes(webtool.id),
                  }}
                  color={
                    webtool.category?.colorCode
                      ? webtool.category.colorCode
                      : "#29394D"
                  }
                  onReach={() => openInNewTab(webtool[`urlName${lng}`])}
                  onUpdate={() => setWebtoolEdited(webtool)}
                  onDelete={() => setWebtoolDeleted(webtool)}
                  onAddFavorite={() => handleSaveFavorite(webtool)}
                  isFavorite={favoriteIds.includes(webtool.id)}
                />
              </div>
            ) : (
              ""
            );
          })
        )}
      </div>
      <ModalConfirm
        type={"delete"}
        isOpen={webtoolDeleted}
        onCancel={() => setWebtoolDeleted(null)}
        onConfirm={() => deleteCard()}
        inProcess={false}
        actionFailed={false}
        title={"Confirmation de suppression"}
        text={"Êtes-vous sûr de bien vouloir supprimer cet élément ?"}
        labelNo={"No"}
        labelYes={"Yes"}
        labelError={"Error"}
      />
    </>
  );
}
export default ManageWebtools;
