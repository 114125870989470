import React from "react";

import _ from "../../i18n";
import classnames from "classnames";
import styles from "./ManagementSpace.module.scss";

const FilterComponent = ({
  filter,
  categories,
  statuses,
  updateKeyWordFilter,
  updateLanguageFilter,
  updateCategoryFilter,
  updateStatusFilter,
}) => {
  const lang = filter.lng?.charAt(0).toUpperCase() + filter.lng?.slice(1);
  return (
    <div className={styles.filter}>
      {filter.keyWord && (
        <div className={classnames(styles.search_frame, "grid-x align-center")}>
          <div className={classnames(styles.search, "cell small-12 medium-5")}>
            <i className="icon icon-magnifier" />
            <input
              id="searchInput"
              type="text"
              value={filter.keyWord}
              placeholder={_("Search...")}
              onChange={(event) => updateKeyWordFilter(event)}
            />
          </div>
          {["fr", "en", "nl"].map((item, i) => {
            return (
              <div
                className={classnames(
                  styles.lng_item,
                  filter.lng === item ? styles.activated : ""
                )}
                onClick={() => updateLanguageFilter(item)}
              >
                {item}
              </div>
            );
          })}
        </div>
      )}
      {categories?.length > 0 && (
        <div className={classnames(styles.tags, "grid-x align-center")}>
          <div
            className={classnames(
              styles.tag_item,
              filter.categoryId === null ? styles.activated : ""
            )}
            onClick={() => updateCategoryFilter({ id: null })}
          >
            {_("All")}
          </div>
          {categories?.map((item, i) => {
            return (
              <div
                className={classnames(
                  styles.tag_item,
                  filter.categoryId === item.id ? styles.activated : ""
                )}
                onClick={() => updateCategoryFilter(item)}
              >
                {item[`name${lang}`]}
              </div>
            );
          })}
        </div>
      )}
      {statuses?.length > 0 && (
        <div className={classnames(styles.tags, "grid-x align-center")}>
          {statuses?.map((status, i) => {
            return (
              <div
                key={`status-${i}`}
                className={classnames(
                  styles.tag_item,
                  filter.status === status ? styles.activated : ""
                )}
                onClick={() => updateStatusFilter(status)}
              >
                {_(status)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
