import { CardTool, ModalConfirm } from "tamtam-components";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "../../i18n";

import { getFFFLibrary, namageWebtool } from "../../api";

import NotFound from "../Commun/NotFound/NotFound";
import Tooltip from "../Library/Tooltip";

import IconClose from "components/Icons/IconClose";
import IconCheck from "components/Icons/IconCheck";
import FilterComponent from "./FilterComponent";
import { cardsFetching } from "utils";
import { debounce } from "lodash";

function FFFLibrary() {
  const { navCommunity, token } = useSelector((state) => state.auth);
  const params = useSelector((state) => state.params);
  const [tooltipIsVisible, setVisibilityTooltip] = useState(null);
  const [webtools, setWebtools] = useState([]);
  const [isFetching, setIsFeching] = useState(false);
  const [rejectingWebtool, setRejectingWebtool] = useState(false);
  const [rejectedWebtool, setRejectedWebtool] = useState(null);
  const [acceptingWebtool, setAcceptingWebtool] = useState(false);
  const [acceptedWebtool, setAcceptedWebtool] = useState(null);

  const [filter, setFilter] = useState({
    lng: params?.language,
    status: "ALL",
    keyword: "",
  });

  const lang = filter.lng.charAt(0).toUpperCase() + filter.lng.slice(1);
  const titleAttr = `title${lang}`;
  const nameAttr = `name${lang}`;
  const urlNameAttr = `urlName${lang}`;
  const descAttr = `urlName${lang}`;

  const fetchWebtools = async () => {
    setIsFeching(true);
    await getFFFLibrary({
      token,
      filter,
      workspace: "rss",
    })
      .then((res) => {
        setIsFeching(false);
        // const data = res.data.data.filter((webtool) => {
        //   let selected = false;
        //   if (filter.lng) {
        //     selected = webtool[titleAttr] !== "";
        //   }
        //   if (filter.keyWord.length > 0) {
        //     selected = webtool[titleAttr]
        //       .toLowerCase()
        //       .includes(filter.keyWord.toLowerCase());
        //   }
        //   return selected;
        // });
        res.data?.data ? setWebtools(res.data.data) : setWebtools([]);
      })
      .catch((e) => {
        setWebtools([]);
      })
      .finally(() => {
        setIsFeching(false);
      });
  };

  const handleKeywordChange = (e) => {
    setFilter({ ...filter, keyword: e.target.value });
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleKeywordChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    fetchWebtools();
  }, [filter]);

  // const allowedActions =
  //   user &&
  //   (user.isUaAdmin ||
  //     (user.role.typeStatus === "LEGAL_REPRESENTATIVE" &&
  //       user.role.type === "MANAGER" &&
  //       user.role.organization.id === navCommunity.id));

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleRejectWebtool = async () => {
    setRejectingWebtool(true);
    await namageWebtool({
      token: token,
      id: rejectedWebtool.id,
      action: "REJECT",
    })
      .then(() => {
        fetchWebtools();
        toast.success(_("data_saved_successfully"));
      })
      .catch((e) => {
        toast.error(_("savingError"), { autoClose: true });
      })
      .finally(() => {
        setRejectingWebtool(false);
        setRejectedWebtool(null);
      });
  };

  const handleAcceptWebtool = async () => {
    setAcceptingWebtool(true);
    try {
      await namageWebtool({
        token: token,
        id: acceptedWebtool.id,
        action: "ACCEPT",
      });
      fetchWebtools();
      toast.success(_("data_saved_successfully"));
      setAcceptingWebtool(false);
      setAcceptedWebtool(null);
    } catch (e) {
      toast.error(_("savingError"), { autoClose: true });
      setAcceptingWebtool(false);
      setAcceptedWebtool(null);
    }
  };

  const renderWebtoolCard = (webtool) => {
    const organizationName =
      webtool.organizationSource && webtool.organizationSource.abbreviation
        ? webtool.organizationSource.abbreviation
        : webtool.organizationSource && webtool.organizationSource.name
        ? webtool.organizationSource.name
        : webtool.organization.abbreviation
        ? webtool.organization.abbreviation
        : webtool.organization.name;
    return (
      <CardTool
        toolContent={
          <Tooltip webtool={webtool} onSavedWebtool={setVisibilityTooltip} />
        }
        displayTooltip={tooltipIsVisible === webtool.id}
        setTooltipVisibility={(element) => {
          setVisibilityTooltip(element ? webtool.id : null);
        }}
        data={{
          id: webtool.id,
          title: webtool[titleAttr],
          link: webtool[urlNameAttr],
          description: webtool[descAttr] ?? "",
          tag: webtool.category[nameAttr],
          icon: webtool.icon?.label,
          created: webtool.createdAt
            ? `${_("createdThe")} ${webtool.createdAt}`
            : "",
          organization: organizationName
            ? `${_("by")} ${organizationName}`
            : "",
          source: webtool.organizationSource ? _("libraryFFF") : null,
        }}
        color={
          webtool.category?.colorCode ? webtool.category.colorCode : "#29394D"
        }
        onReach={() => openInNewTab(webtool[urlNameAttr])}
      />
    );
  };

  // let cardsFetching = [];
  // for (let j = 0; j < 8; j++) {
  //   cardsFetching.push(
  //     <div
  //       className="cell small-12 medium-4 large-3"
  //       style={{ padding: "15px 15px 0 0px" }}
  //     >
  //       <div key={j} className="card_tool_fetching gradiant" />
  //     </div>
  //   );
  // }
  return (
    <>
      <FilterComponent
        filter={filter}
        statuses={["ALL", "PROPOSED", "ACCEPTED", "REJECTED"]}
        updateKeyWordFilter={debouncedResults}
        updateLanguageFilter={(lng) => {
          setFilter((previousState) => {
            return { ...previousState, lng };
          });
        }}
        updateStatusFilter={(status) => {
          setFilter((previousState) => {
            return { ...previousState, status: status };
          });
        }}
      />
      <div className="grid-x">
        {isFetching ? (
          cardsFetching(8)
        ) : webtools.length === 0 ? (
          <NotFound />
        ) : (
          webtools.map((webtool) => {
            return webtool.fffLibrary === "PROPOSED" ? (
              <div
                className="cell small-12 medium-4 large-3"
                style={{ padding: "20px 15px 0 0px" }}
              >
                <div className="wrap-card">
                  <div className="lib-action">
                    <div
                      onClick={() => {
                        setAcceptedWebtool(webtool);
                      }}
                    >
                      <IconCheck />
                    </div>
                    <div
                      onClick={() => {
                        setRejectedWebtool(webtool);
                      }}
                    >
                      <IconClose size={14} />
                    </div>
                  </div>
                  {renderWebtoolCard(webtool)}
                </div>
              </div>
            ) : (
              <div
                className="cell small-12 medium-4 large-3"
                style={{ padding: "20px 15px 0 0px" }}
              >
                {renderWebtoolCard(webtool)}{" "}
              </div>
            );
          })
        )}
      </div>
      <ModalConfirm
        type={"delete"}
        isOpen={rejectedWebtool !== null}
        onCancel={() => setRejectedWebtool(null)}
        onConfirm={handleRejectWebtool}
        inProcess={rejectingWebtool}
        title={_("confirmReject")}
        text={_("confirmRejectMessage")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      <ModalConfirm
        type={"publish"}
        isOpen={acceptedWebtool !== null}
        onCancel={() => setAcceptedWebtool(null)}
        onConfirm={handleAcceptWebtool}
        inProcess={acceptingWebtool}
        title={_("confirmAccept")}
        text={_("confirmAcceptMessage")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </>
  );
}
export default FFFLibrary;
